import React from 'react';
import ReactGA from 'react-ga'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';
import RouteChangeTracker from './utils/RouteChangeTracker';

// Pages import Here
import Home from "./pages/Home";

// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
 
    const TRACKING_ID = 'G-DQ61D3NYK7'; 
    ReactGA.initialize(TRACKING_ID);
    return (

        <Router>
            <RouteChangeTracker />

            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                </Switch>
            </PageScrollTop>

        </Router>
    )
}


export default App
